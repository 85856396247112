<template>
    <div>
        <simple-wizard 
            ref="wizard" 
            next-button-text="Avanti" 
            prev-button-text="Indietro" 
            :has-top-summary="hasTopSummary"
            :show-custom-buttons="true"
            @tab-change="tabChange"
        >
            <template slot="header">
                <h3 class="card-title text-left">{{ this.value.id ? 'Modifica prenotazione' : 'Inserimento nuova prenotazione' }}</h3>
            </template>
        
            <template #topSummary>
                <TopSummary :order="value" :is-review-tab="isReviewTabActive"></TopSummary>
                <CartSummary 
                    v-if="hasCart && (isProductTabActive || isReviewTabActive)" 
                    :order="value" 
                    :is-review-tab-active="isReviewTabActive"
                    :flat-total="flatTotal" 
                    :total-quantity="totalQuantity"
                    :total-quantity-without-gifts-and-replacements="totalQuantityWithoutGiftsAndReplacements"
                    :total-unassigned-free-bottles="totalUnassignedFreeBottles"
                    :total="total" 
                    :total-discount="totalDiscount" 
                    :customer-discount-percentage="customerDiscountPercentage"
                    :discount-percentage="discountPercentage"
                    :total-discounted="totalDiscounted"
                    :total-discounted-with-shipping="totalDiscountedWithShipping"
                    :total-discounted-with-shipping-and-iva="totalDiscountedWithShippingAndIva"
                    :shipping-costs="shippingCosts"
                    :apiErrors="apiErrors"
                    @onCartItemUpdate="setCartItem($event)" 
                    @onCartItemRemove="removeCartItem($event)"
                >
                </CartSummary>
            </template>
        
            <template #customButtons>
                <div class="col-12 mt-4">
                    <div class="pull-right">
                        <base-button type="primary" wide class="btn-next" :disabled="saveAsDraftDisabledStatus" :loading="isLoading" @click="$emit('saveAsDraft')">
                            Salva come Bozza
                        </base-button>
                        <base-button v-if="!value.id" type="primary" wide class="btn-next" :disabled="isLoading" @click="$emit('cancelOrder')">
                            Annulla
                        </base-button>
                        <base-button v-if="!isReviewTabActive" type="primary" wide class="btn-next" :disabled="nextButtonDisabledStatus" @click="nextButtonAction($refs.wizard)">
                            Avanti
                        </base-button>
                        <base-button v-if="isReviewTabActive" type="primary" wide class="btn-next" :disabled="saveButtonDisabledStatus" :loading="isLoading" @click="$emit('saveOrder')">
                            Conferma prenotazione
                        </base-button>
                    </div>
        
                    <div class="pull-left">
                        <base-button wide type="primary" class="btn-previous" :disabled="isLoading" @click.native="$refs.wizard.prevTab()">
                            Indietro
                        </base-button>
                    </div>
                </div>
            </template>
        
            <wizard-tab :before-change="() => validateStep('step1')">
                <template slot="label">
                    <i class="tim-icons icon-single-02"></i>
                    <p>Cliente</p>
                </template>
                <step1 
                    ref="step1" 
                    :order="value"
                    :payment-methods="paymentMethods"
                    :electronic-invoice-routings="electronicInvoiceRoutings"
                    :states="states"
                    :provinces="provinces"
                    @onCustomerSelection="setCustomer($event)" 
                />
            </wizard-tab>
        
            <wizard-tab :before-change="() => validateStep('step2')">
                <template slot="label">
                    <i class="tim-icons icon-align-left-2"></i>
                    <p style="min-width: 150px">Indirizzo di consegna</p>
                </template>
                <step2 ref="step2" 
                    :order="value"
                    :provinces="provinces"
                    :customer="value.customer" 
                    @onCustomerAddressSelection="setCustomerAddress($event)" 
                />
            </wizard-tab>
        
            <wizard-tab :before-change="() => validateStep('step3')">
                <template slot="label">
                    <i class="tim-icons icon-credit-card"></i>
                    <p>Pagamento</p>
                </template>
                <step3 ref="step3" 
                    :order="value"
                    :payment-methods="paymentMethods"
                    @onPaymentMethodSelection="setPaymentMethod($event)" 
                />
            </wizard-tab>
        
            <wizard-tab :before-change="() => validateStep('step4')">
                <template slot="label">
                    <i class="tim-icons icon-cart"></i>
                    <p>Articoli</p>
                </template>
                <step4 
                    ref="step4" 
                    :order="value"
                    :apiErrors="apiErrors"
                    @onCartItemUpdate="setCartItem($event)" 
                />
            </wizard-tab>
        
            <wizard-tab :before-change="() => validateStep('step5')">
                <template slot="label">
                    <i class="tim-icons icon-delivery-fast"></i>
                    <p style="min-width: 150px">Specifiche di consegna</p>
                </template>
                <step5 ref="step5" 
                    :order="value"
                    :shipping-methods="shippingMethods" 
                    :total-discounted="totalDiscounted"
                    :delivery-notes="deliveryNotes"
                    @onShippingMethodSelection="setShippingMethod($event)"
                    @updateNotes="updateNotes($event)"
                    @updateDeliveryFloor="updateDeliveryFloor($event)"
                    @updateAppointmentPhoneNumber="updateAppointmentPhoneNumber($event)"
                    @updateMandatoryDeliveryDate="updateMandatoryDeliveryDate($event)"
                    @updateMandatoryDeliveryMaxDate="updateMandatoryDeliveryMaxDate($event)"
                    @onDeliveryNoteSelection="setDeliveryNote($event)"
                />
            </wizard-tab>
        
            <wizard-tab :before-change="() => validateStep('step6')">
                <template slot="label">
                    <i class="tim-icons icon-notes"></i>
                    <p>Anteprima</p>
                </template>
                <step6 ref="step6" />
            </wizard-tab>
        </simple-wizard>
    </div>
</template>

<script>
import TopSummary from './Components/TopSummary.vue'
import CartSummary from './Components/CartSummary.vue'
import { Step1, Step2, Step3, Step4, Step5, Step6 } from './Steps/steps.js'
import { SimpleWizard, WizardTab } from '@/theme/components/index'
import PackagingMixin from '@/mixins/PackagingMixin'
import GiftProductsMixin from '@/mixins/GiftProductsMixin';

export default {
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    customer: null,
                    customer_address: null,
                    payment_method: null,
                    shipping_method: null,
                    cart: [],
                    total: null,
                    reservation: true,
                    notes: null,
                    delivery_notes: null,
                    delivery_floor: null,
                    appointment_phone_number: null,
                    mandatory_delivery_date: null,
                    mandatory_delivery_max_date: null,
                    additional_costs: null
                }
            }
        },
        paymentMethods: {
            type: Array,
            default: () => {
                return []
            }
        },
        shippingMethods: {
            type: Array,
            default: () => {
                return []
            }
        },
        deliveryNotes: {
          type: Array,
          default: () => {
            return []
          }
        },
        electronicInvoiceRoutings: {
            type: Array,
            default: () => {
                return []
            }
        },
        states: {
            type: Array,
            default: () => {
                return []
            }
        },
        provinces: {
            type: Array,
            default: () => {
                return []
            }
        },
        flatCart: {
            type: Array,
            default: () => {
                return []
            }
        },
        flatTotal: {
            type: String,
            default: null
        },
        totalQuantity: {
            type: Number,
            default: null
        },
        totalQuantityWithoutGiftsAndReplacements: {
            type: Number,
            default: null
        },
        totalUnassignedFreeBottles: {
            type: Number,
            default: null
        },
        total: {
            type: String,
            default: null
        },
        totalDiscount: {
            type: String,
            default: null
        },
        totalDiscounted: {
            type: String,
            default: null
        },
        customerDiscountPercentage: {
            type: String,
            default: null
        },
        discountPercentage: {
            type: String,
            default: null
        },
        totalDiscountedWithShipping: {
            type: String,
            default: null
        },
        totalDiscountedWithShippingAndIva: {
            type: String,
            default: null
        },
        shippingCosts: {
            type: String,
            default: null
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        apiErrors: {
            type: [Object, String],
            default: () => {
                return {}
            }
        }
    },

    components: {
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
        TopSummary,
        CartSummary,
        SimpleWizard,
        WizardTab
    },

    mixins: [PackagingMixin, GiftProductsMixin],

    data() {
        return {
            activeTab: 0
        }
    },

    watch: {
        apiErrors: {
            handler(val) {
                if (val.type === 'storage') {
                    this.$refs.wizard.navigateToTab(3)
                }
            },
            deep: true
        }
    },

    computed: {
        hasTopSummary() {
            return this.value.customer != null;
        },
        hasCart() {
            return this.value.cart && this.value.cart.length > 0
        },
        isProductTabActive() {
            return this.activeTab === "3"
        },
        isReviewTabActive() {
            return this.activeTab === "5"
        },
        saveAsDraftDisabledStatus() {
            return this.value.cart.length <= 0;
        },
        nextButtonDisabledStatus() {
            switch (String(this.activeTab)) {
                case "0":
                    return this.$refs["step1"] && !this.$refs["step1"].validate();
                case "1":
                    return this.$refs["step2"] && !this.$refs["step2"].validate();
                case "2":
                    return this.$refs["step3"] && !this.$refs["step3"].validate();
                case "3":
                    return this.$refs["step4"] && (!this.$refs["step4"].validate() || !this.validateProductReplacementNotes)
                case "4":
                    return this.$refs["step5"] && (!this.$refs["step5"].validate() || !this.validateProductReplacementNotes)
                default:
                    return false
            }
        },
        saveButtonDisabledStatus() {
            return !this.validateProductReplacementNotes
        },
        validateProductReplacementNotes() {
            var valid = true
            this.value.cart.forEach((item) => {
                if (item.replacement &&
                    (!item.replacement_invoice_number || !item.replacement_event_date || !item.replacement_reason)
                ) {
                    valid = false
                }
            })
            return valid
        }
    },

    mounted() {
        if (this.$route.hash === '#3') {
            this.$refs.wizard.activeTabIndex = 3
        }
    },

    methods: {
        nextButtonAction(wizard) {
            let order = { ...this.value }
            if (wizard.activeTab.tabId == 2 && !order.id) {
                this.$emit('saveDraftAndReopenForEdit')
            } else {
                return wizard.nextTab()
            }
        },
        setCustomer(customer) {
            let order = { ...this.value }
            order.customer = customer
            if(customer.payment_method) {
                order.payment_method = customer.payment_method
            }
            this.$emit('input', order)
        },
        setCustomerAddress(customerAddress) {
            let order = { ...this.value }
            order.customer_address = customerAddress
            this.$emit('input', order)
        },
        setPaymentMethod(paymentMethod) {
            let order = { ...this.value }
            order.payment_method = paymentMethod
            this.$emit('input', order)
        },
        setShippingMethod(shippingMethod) {
            let order = { ...this.value }
            order.shipping_method = shippingMethod
            this.$emit('input', order)
        },
        setDeliveryNote(selectedDeliveryNotes) {
            let order = { ...this.value }
            order.delivery_notes = selectedDeliveryNotes
            order.additional_costs = this.additionalCosts(order)
            this.$emit('input', order)
        },
        setCartItem(data) {
            let order = { ...this.value }
            if (data.index != null && data.index >= 0) {
                order.cart[data.index] = data.productData
            } else {
                order.cart.push(data.productData);
            }
            this.fixCartRanks()
            order.additional_costs = this.additionalCosts(order)
            this.$emit('input', order)
        },
        removeCartItem(data) {
            let order = { ...this.value }
            if (data.index >= 0) {
                order.cart.splice(data.index, 1);
            }
            this.fixCartRanks()
            order.additional_costs = this.additionalCosts(order)
            this.$emit('input', order)
        },
        fixCartRanks() {
            this.value.cart.forEach((cartItem, index) => {
                cartItem.rank = index + 1
            })
        },
        updateNotes(notes) {
            let order = { ...this.value }
            order.notes = notes
            this.$emit('input', order)
        },
        updateAppointmentPhoneNumber(appointmentPhoneNumber) {
            let order = { ...this.value }
            order.appointment_phone_number = appointmentPhoneNumber
            this.$emit('input', order)
        },
        updateDeliveryFloor(deliveryFloor) {
            let order = { ...this.value }
            order.delivery_floor = deliveryFloor
            this.$emit('input', order)
        },
        updateMandatoryDeliveryDate(mandatoryDeliveryDate) {
            let order = { ...this.value }
            order.mandatory_delivery_date = mandatoryDeliveryDate
            this.$emit('input', order)
        },
        updateMandatoryDeliveryMaxDate(mandatoryDeliveryMaxDate) {
          let order = { ...this.value }
          order.mandatory_delivery_max_date = mandatoryDeliveryMaxDate
          this.$emit('input', order)
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        },
        tabChange(oldTab, newTab) {
            this.activeTab = newTab.tabId
        },
        validateStep(ref) {
            return this.$refs[ref].validate();
        },
        additionalCosts(order) {
            let additionalCosts = []

            if (this.deliveryNotes && order) {
                this.deliveryNotes.forEach((item, index) => {
                    if (parseFloat(item.price) > 0 && order.delivery_notes && order.delivery_notes.includes(item.id)) {
                        let price = 0.0
                        if (item.divisor_bottles > 0) {
                            price = parseFloat(item.price) * Math.ceil(this.totalQuantity / item.divisor_bottles)
                        } else {
                            price = parseFloat(item.price)
                        }

                        let note = item.note
                        if (item.id == 12 || item.id == 13) {
                            note = 'Tassativa' // devo togliere dal testo "entro il", altrimenti poi devo metterci anche la data!
                        }

                        additionalCosts.push({
                            'description': note.toUpperCase(),
                            'price': price.toFixed(2)
                        })
                    }
                })
            }

            return additionalCosts
        }
    }
}
</script>