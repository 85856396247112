<template>
    <div className="row">
        <div className="col-12">
            <card>
                <page-header
                    title="SCADENZIARIO"
                    @backClick="$router.back()"
                />

                <list-table
                    v-model="query"
                    :pagination="pagination"
                    :columns="tableColumns"
                    :items="timetables"
                    :search-fields="searchFields"
                    :meta="meta"
                    :creable="false"
                    :deletable="false"
                    :editable="false"
                    :showable="false"
                    name-prop="last_name">

                    <template #customActions="props">
                        <el-tooltip content="Scarica Documento" :effect="props.darkMode ? 'light' : 'dark'"
                                    :open-delay="300" placement="top"
                                    v-if="props.row.document_downloadable">
                            <base-button class="edit btn-link" type="warning" size="sm" icon
                                         @click.native="downloadPdf(props.row)">
                                <i v-if="!isDownloading" class="tim-icons icon-paper" />
                                <i v-else class="fas fa-spinner fa-spin" />
                            </base-button>
                        </el-tooltip>
                    </template>

                </list-table>
            </card>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {ListTable} from '@/theme/components/index'
import secureStorage from '@/utils/secureStorage'
import axios from "axios";

export default {
    components: {
        ListTable
    },

    data() {
        return {
            isDownloading: false,
            query: {
                rowsPerPage: 25,
                page: 1,
                sort: null,
                filter: {
                    custom_search: ''
                }
            },
            pagination: {
                perPageOptions: [10, 25, 50, 100],
                total: 0
            },
            tableColumns: [
                {
                    prop: 'company_name',
                    label: 'Nome',
                    sortable: true,
                    minWidth: 200
                },
                {
                    prop: 'invoice_number',
                    label: 'n. ft',
                    width: 50
                },
                {
                    prop: 'invoice_date',
                    label: 'data ft',
                    sortable: true
                },
                {
                    prop: 'due_date',
                    label: 'scadenza',
                    sortable: true
                },
                {
                    prop: 'payment_date',
                    label: 'pagamento',
                    sortable: true
                },
                {
                    prop: 'instalment_amount',
                    label: 'tot €',
                },
                {
                    prop: 'status_html',
                    label: 'stato',
                    isHtml: true
                },
            ]
        }
    },

    computed: {
        ...mapGetters({
            timetables: 'timetables/items',
            meta: 'timetables/meta'
        }),
        searchFields() {
          return [
            {
              propName: 'status',
              type: 'select',
              data: [{name: 'PAGATA'}, {name: 'DA PAGARE'}, {name: 'SCADUTA'}],
              name: 'STATO PAGAMENTO',
              listItemValue: 'name'
            }
          ]
        }
    },

    watch: {
        query: {
            handler() {
                this.handleFetch()
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        }
    },

    created() {
        const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
        if(this.$route.query.custom_search){
          this.query.filter.custom_search = this.$route.query.custom_search
        }
        if (storedQuery && storedQuery.entity === 'timetables') {
            this.query = storedQuery.query
        } else {
            this.handleFetch()
        }
    },

    beforeDestroy() {
        this.clearTimetables()
    },

    methods: {
        ...mapActions({
            fetchTimetables: 'timetables/fetch',
            clearTimetables: 'timetables/resetItems'
        }),
        handleFetch() {
            this.fetchTimetables(this.query)
            secureStorage.setItem('tableQuery', JSON.stringify({entity: 'timetables', query: this.query}))
        },
        downloadPdf(item) {
            let filename = 'Documento_' + item.invoice_number + '.pdf'
            if(!this.isDownloading) {
                this.isDownloading = true
                axios({
                    url: '/timetable/' + item.id + '/getDocument',
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    if (response.status == 205) {
                        this.$notify({
                            message: 'Documento non disponibile.',
                            timeout: 5000,
                            icon: '',
                            horizontalAlign: 'right',
                            verticalAlign: 'top',
                            type: 'warning'
                        })
                        this.isDownloading = false
                    } else {
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(new Blob([response.data]))
                        link.setAttribute('download', filename);
                        link.click()
                        this.isDownloading = false
                    }
                });
            }
        },
    }
}
</script>
<style>
.timetable-payed {
  color: green;
}
.timetable-past {
  color: red;
}
.timetable-due {
  color: #b36000;
}
</style>
