<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header title="NUOVO CLIENTE" :loading="isLoading" @backClick="$router.back()" />
      </card>
      <customer-form
        v-model="customer"
        :payment-methods="paymentMethods"
        :electronic-invoice-routings="electronicInvoiceRoutings"
        :states="states"
        :provinces="provinces"
        :api-errors="apiErrors"
        :is-loading="isLoading"
        @submit="handleSubmit"
        @input="updateValue($event)"
        @clearErrors="apiErrors = {}"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import CustomerForm from './Form.vue'

export default {
  components: { CustomerForm },

  data () {
    return {
      customer: {
        business_name: null,
        email: null,
        client_code: null,
        pec: null,
        discount: 0,
        vat: null,
        fiscal_code: null,
        address: null,
        city: null,
        cap: null,
        state_id: null,
        province_id: null,
        payment_method_id: null,
        electronic_invoice_routing_id: null,
        customer_addresses: [],
        notes: null
      },
      isLoading: false,
      apiErrors: {}
    }
  },

  computed: {
    ...mapGetters({
      paymentMethods: 'paymentMethods/items',
      electronicInvoiceRoutings: 'electronicInvoiceRoutings/items',
      states: 'states/items',
      provinces: 'provinces/items'
    })
  },

  created () {
    this.fetchPaymentMethods()
    this.fetchElectronicInvoiceRoutings()
    this.fetchStates()
    this.fetchProvinces()
  },

  beforeDestroy () {
    this.clearPaymentMethods()
    this.clearElectronicInvoiceRoutings()
    this.clearStates()
    this.clearProvinces()
  },

  methods: {
    ...mapActions({
      fetchPaymentMethods: 'paymentMethods/fetch',
      fetchElectronicInvoiceRoutings: 'electronicInvoiceRoutings/fetch',
      fetchStates: 'states/fetch',
      fetchProvinces: 'provinces/fetch',
      createCustomer: 'customers/create',
      clearPaymentMethods: 'paymentMethods/resetItems',
      clearElectronicInvoiceRoutings: 'electronicInvoiceRoutings/resetItems',
      clearStates: 'states/resetItems',
      clearProvinces: 'provinces/resetItems',
      updateValue: 'customers/updateValue'
    }),
    handleSubmit () {
      this.isLoading = true
      this.createCustomer({ payload: this.customer }).then((res) => {
        this.isLoading = false
        this.$notify({
          message:
                        'Cliente creato con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.$router.push('/clienti')
      }).catch((err) => {
        this.isLoading = false
        if (err.response.status === 422) {
          this.apiErrors = err.response.data.content.message
        };
      })
    }
  }
}
</script>
<style>

</style>
