<template>
  <div class="row">
    <div class="col-12 mt-3">
      <ValidationObserver ref="form">
        <form @submit.prevent="false">
          <div class="row">
            <div class="col-md-6 mt-2">
              <h4>Scegli la modalità di spedizione</h4>
            </div>
            <div class="col-md-6 mt-2">
              <h4>Indica le note di consegna</h4>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-2">
              <div v-for="shippingMethod in shippingMethods">
                <base-radio
                  v-model="selectedRadio"
                  :name="shippingMethod.id"
                  @input="selectShippingMethod(shippingMethod)"
                >
                  {{ `${shippingMethod.description} (${calculateShippingPrice(shippingMethod)} €)` }}
                </base-radio>
              </div>
            </div>
            <div class="col-md-6 mt-2">
              <div v-for="deliveryNote in deliveryNotes">
                <base-checkbox v-model="selectedDeliveryNotes" :value="deliveryNote.id"
                     :name="deliveryNote.id"
                     @input="selectDeliveryNote(selectedDeliveryNotes, deliveryNote)"
                >
                  {{ `${deliveryNote.note}` }}
                  <span v-if="deliveryNote.price > 0">( € {{ `${deliveryNote.price_fmt}` }}
                    <span v-if="deliveryNote.divisor_bottles > 0"> ogni {{ `${deliveryNote.divisor_bottles}` }} bottiglie</span>
                    )
                  </span>
                </base-checkbox>
                <span v-if="deliveryNote.id == 1 && selectedDeliveryNotes.includes(1)">
                    <ValidationProvider v-slot="{ passed, failed, errors }" name="appointmentPhoneNumber" :rules="{ required: (selectedDeliveryNotes.includes(1)) }" mode="eager">
                      <base-input
                          type="text"
                          placeholder="Numero di telefono"
                          :value="order.appointment_phone_number"
                          :error="errors[0]"
                          :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                          @input="$emit('updateAppointmentPhoneNumber', $event)"
                      />
                    </ValidationProvider>
                </span>
                <span v-else-if="deliveryNote.id == 6 && selectedDeliveryNotes.includes(6)">
                    <ValidationProvider v-slot="{ passed, failed, errors }" name="deliveryFloor" :rules="{ required: (selectedDeliveryNotes.includes(6)) }" mode="eager">
                      <base-input
                          type="text"
                          placeholder="Indicare il piano"
                          :value="order.delivery_floor"
                          :error="errors[0]"
                          :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                          @input="$emit('updateDeliveryFloor', $event)"
                      />
                    </ValidationProvider>
                </span>
                <span v-else-if="deliveryNote.id == 12 && selectedDeliveryNotes.includes(12)">
                    <ValidationProvider v-slot="{ passed, failed, errors }" name="mandatoryDeliveryDate" :rules="{ required: (selectedDeliveryNotes.includes(12)) }" mode="eager">
                      <base-input
                          type="date"
                          placeholder="Data di consegna"
                          :value="order.mandatory_delivery_date"
                          :error="errors[0]"
                          :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                          @input="$emit('updateMandatoryDeliveryDate', $event)"
                      />
                    </ValidationProvider>
                </span>
                <span v-else-if="deliveryNote.id == 13 && selectedDeliveryNotes.includes(13)">
                    <ValidationProvider v-slot="{ passed, failed, errors }" name="mandatoryDeliveryMaxDate" :rules="{ required: (selectedDeliveryNotes.includes(13)) }" mode="eager">
                      <base-input
                          type="date"
                          placeholder="Data di consegna"
                          :value="order.mandatory_delivery_max_date"
                          :error="errors[0]"
                          :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                          @input="$emit('updateMandatoryDeliveryMaxDate', $event)"
                      />
                    </ValidationProvider>
                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-4">
              <ValidationProvider v-slot="{ passed, failed, errors }" name="note" :rules="{ max: 240 }" mode="eager">
                <base-input
                  type="text"
                  label="Note Integrative (max.240)"
                  placeholder="Note Integrative"
                  :value="order.notes"
                  :error="errors[0]"
                  :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                  @input="$emit('updateNotes', $event)"
                />
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { extend } from 'vee-validate'
import { max } from 'vee-validate/dist/rules'

extend('max', max)

export default {
  props: {
    order: {
      type: Object,
      default: () => {
        return {}
      }
    },
    shippingMethods: {
      type: Array,
      default: () => {
        return []
      }
    },
    deliveryNotes: {
      type: Array,
      default: () => {
        return []
      }
    },
    totalDiscounted: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      selectedRadio: null,
      selectedDeliveryNotes: []
    }
  },

  watch: {
    order: {
      handler (val) {
        if (val && val.shipping_method && val.shipping_method.id) {
          this.selectedRadio = val.shipping_method.id
        }
        if (val && val.delivery_notes) {
          this.selectedDeliveryNotes = val.delivery_notes
        }
      },
      immediate: true,
      deep: true
    }
  },

  methods: {
    validate () {
      return this.order.shipping_method
              && (!this.order.notes || this.order.notes.length <= 240)
              && (!this.selectedDeliveryNotes.includes(1) || (this.selectedDeliveryNotes.includes(1) && this.order.appointment_phone_number))
              && (!this.selectedDeliveryNotes.includes(6) || (this.selectedDeliveryNotes.includes(6) && this.order.delivery_floor))
              && (!this.selectedDeliveryNotes.includes(12) || (this.selectedDeliveryNotes.includes(12) && this.order.mandatory_delivery_date))
              && (!this.selectedDeliveryNotes.includes(13) || (this.selectedDeliveryNotes.includes(13) && this.order.mandatory_delivery_max_date))
    },
    calculateShippingPrice (shippingMethod) {
      if (shippingMethod.threshold && this.totalDiscounted >= shippingMethod.threshold) {
        return parseFloat(shippingMethod.threshold_price).toFixed(2)
      } else {
        return parseFloat(shippingMethod.price).toFixed(2)
      }
    },
    selectShippingMethod (shippingMethod) {
      this.$emit('onShippingMethodSelection', shippingMethod)
    },
    selectDeliveryNote (selectedDeliveryNotes, deliveryNote) {
      // le note 12 e 13 sono mutualmente esclusive: o una o l'altra, o nessuna
      if (deliveryNote.id == 12) {
        var index = selectedDeliveryNotes.indexOf(13)
        if (index >= 0) {
          selectedDeliveryNotes.splice(index, 1)
        }
      }
      else if (deliveryNote.id == 13) {
        var index = selectedDeliveryNotes.indexOf(12)
        if (index >= 0) {
          selectedDeliveryNotes.splice(index, 1)
        }
      }
      this.$emit('onDeliveryNoteSelection', selectedDeliveryNotes)
    }
  }
}
</script>
