<template>
  <div className="row">
    <div className="col-12">
      <card>
        <page-header
          title="PROVVIGIONI"
          @backClick="$router.back()"
        />

        <list-table
          v-model="query"
          :pagination="pagination"
          :columns="tableColumns"
          :items="commissions"
          :search-fields="searchFields"
          :meta="meta"
          :creable="false"
          :deletable="false"
          :editable="false"
          :showable="false"
          :hasActions="false"
          name-prop="last_name"
        />
      </card>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {ListTable} from '@/theme/components/index'
import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data() {
    return {
      query: {
        rowsPerPage: 25,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [10, 25, 50, 100],
        total: 0
      },
      tableColumns: [
        {
          prop: 'company_name',
          label: 'Nome',
          sortable: true,
          minWidth: 200
        },
        {
          prop: 'document_type',
          label: 'tipo'
        },
        {
          prop: 'document_number',
          label: 'num.',
          width: 50
        },
        {
          prop: 'document_date',
          label: 'data ft.',
          sortable: true
        },
        {
          prop: 'document_total',
          label: 'tot. ft.',
          sortable: false
        },
        {
          prop: 'total_taxable_for_revenue',
          label: 'imponibile provv.',
          sortable: false
        },
        {
          prop: 'paid_agent_revenue',
          label: 'provv. liquidata',
          sortable: false
        },
        {
          prop: 'status_html',
          label: 'stato',
          isHtml: true
        },
        {
          prop: 'payment_status_html',
          label: 'stato fatt.',
          isHtml: true
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      commissions: 'commissions/items',
      meta: 'commissions/meta'
    }),
    searchFields() {
      return [
        {
          propName: 'status',
          type: 'select',
          data: [{name: 'LIQUIDATA'}, {name: 'DA LIQUIDARE'}],
          name: 'STATO PAGAMENTO',
          listItemValue: 'name'
        },
        {
          propName: 'payment_status',
          type: 'select',
          data: [{payment_name: 'PAGATA'}, {payment_name: 'DA PAGARE'}, {payment_name: 'SCADUTA'}],
          name: 'STATO FATTURA',
          listItemValue: 'payment_name'
        }
      ]
    }
  },

  watch: {
    query: {
      handler() {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler(val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created() {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'commissions') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy() {
    this.clearCommissions()
  },

  methods: {
    ...mapActions({
      fetchCommissions: 'commissions/fetch',
      clearCommissions: 'commissions/resetItems'
    }),
    handleFetch() {
      this.fetchCommissions(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({entity: 'commissions', query: this.query}))
    },
  }
}
</script>
<style>
.timetable-payed {
  color: green;
}
.timetable-past {
  color: red;
}
.timetable-due {
  color: #b36000;
}
</style>
