<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
          title="MODIFICA AGENTE"
          :loading="isLoading"
          @backClick="$router.back()"
        />
        <agent-form
          :value="agent"
          :api-errors="apiErrors"
          :is-loading="isLoading"
          :deletable="false"
          @submit="handleSubmit"
          @input="updateValue($event)"
          @clearErrors="apiErrors = {}"
        />
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import AgentForm from './Form.vue'

export default {
  components: { AgentForm },
  data () {
    return {
      agentId: null,
      isLoading: false,
      apiErrors: {}
    }
  },

  computed: {
    ...mapGetters({
      agent: 'agents/single'
    })
  },

  created () {
    this.agentId = this.$route.params.id
    this.getAgent(this.agentId)
  },

  beforeDestroy () {
    this.clearAgent()
  },

  methods: {
    ...mapActions({
      getAgent: 'agents/get',
      updateAgent: 'agents/update',
      reloadUser: 'auth/reload',
      clearAgent: 'agents/resetSingle',
      updateValue: 'agents/updateValue'
    }),
    handleSubmit () {
      this.isLoading = true
      this.updateAgent({ id: this.agentId, payload: this.agent }).then((res) => {
        this.$notify({
          message:
            'Agent aggiornato con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.reloadUser().then((res) => {
          this.isLoading = false
          this.$router.push('/agenti')
        })
      }).catch((err) => {
        this.isLoading = false
        if (err.response.status === 422) {
          this.apiErrors = err.response.data.content.message
        };
      })
    },
  }
}
</script>
<style></style>
