<template>

</template>

<script>


export default {
    methods: {
        validate() {
            return true
        },
    }
}
</script>