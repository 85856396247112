<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
          title="CLIENTI"
          :query="query"
          @backClick="$router.back()"
        />
        <list-table
          v-model="query"
          :pagination="pagination"
          :columns="tableColumns"
          :items="customers"
          :meta="meta"
          :search-fields="searchFields"
          name-prop="business_name"
          hide-default-search
          :deletable="false"
          :creable="!user.superagent"
          :editable="!user.superagent"
          :showable="false"
          new-button-label="NUOVO CLIENTE"
          @onNew="$router.push('clienti/nuovo')"
          @onEdit="$router.push(`clienti/${$event.id}/modifica`)"
        >
          <template #extraActions="rowProps">
            <el-tooltip content="Ordini" :effect="darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button class="remove btn-link" type="danger" size="sm" icon @click="$router.push({name: 'Orders', query:{custom_search: rowProps.row.business_name}})">
                O
              </base-button>
            </el-tooltip>
            <el-tooltip content="Prenotazioni" :effect="darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button class="remove btn-link" type="danger" size="sm" icon @click="$router.push({name: 'Prenotazioni', query:{custom_search: rowProps.row.business_name}})">
                P
              </base-button>
            </el-tooltip>
            <el-tooltip content="Fatture" :effect="darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="$router.push({name: 'Schedules', query:{custom_search: rowProps.row.business_name}})">
                F
              </base-button>
            </el-tooltip>
          </template>
        </list-table>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data () {
    return {
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
        {
          type: 'input',
          propName: 'custom_search',
          name: 'Cerca'
        }
      ],
      tableColumns: [
        {
          prop: 'business_name',
          label: 'Nome',
          sortable: true,
          minWidth: 200
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      customers: 'customers/items',
      meta: 'customers/meta',
      darkMode: 'settings/darkMode'
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler (val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created () {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'customers') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy () {
    this.clearCustomers()
  },

  methods: {
    ...mapActions({
      fetchCustomers: 'customers/fetch',
      deleteCustomer: 'customers/delete',
      clearCustomers: 'customers/resetItems'
    }),
    handleFetch () {
      this.fetchCustomers(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'customers', query: this.query }))
    },
    handleDelete (id) {
      this.deleteCustomer(id).then((res) => {
        this.fetchCustomers(this.query)
        this.$notify({
          message:
                        'Cliente eliminato con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
      })
    }
  }
}
</script>
