<template>
    <div>
        <div class="row d-flex justify-content-center">
            <div class="col-12 card">
                <div v-if="reservation" class="row">
                    <div class="col-12 pt-4 pb-4 pl-4 pr-4">
                        <div class="card-title">
                            <h3>
                                Visualizza Prenotazione
                                <span v-if="!reservation.order_status.is_persistent">
                                    (BOZZA)
                                </span>
                                <span v-if="reservation.order_status.is_expired">
                                    (SCADUTA)
                                </span>
                            </h3>
                        </div>
                        <TopSummary :order="reservation" :is-review-tab="true"></TopSummary>
                        <CartSummary :order="reservation" :is-review-tab-active="true"
                            :flat-total="flatTotal"
                            :total-quantity="totalQuantity"
                            :total-quantity-without-gifts-and-replacements="totalQuantityWithoutGiftsAndReplacements"
                            :total-unassigned-free-bottles="totalUnassignedFreeBottles"
                            :total="total" :total-discount="totalDiscount" :total-discounted="totalDiscounted"
                            :total-discounted-with-shipping="totalDiscountedWithShipping"
                            :discount-percentage="discountPercentage"
                            :shipping-costs="shippingCosts"
                            :total-discounted-with-shipping-and-iva="totalDiscountedWithShippingAndIva"
                            :delivery-notes="deliveryNotes"
                            :is-draft="!reservation.order_status.is_persistent"
                            :isView="isView"
                            readonly />
                    </div>

                    <div v-if="(!user.superagent && reservation.order_status.is_persistent && !reservation.order_status.is_expired && !$isAdmin())" class="col-12 pb-4 pr-4 text-right">
                        <base-button @click="saveOrder" :loading="isLoading">
                            Trasforma in Ordine
                        </base-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import TopSummary from './Components/TopSummary.vue'
import CartSummary from './Components/CartSummary.vue'
import { mapActions, mapGetters } from 'vuex';


import OrderMixin from '@/mixins/OrderMixin'
import OrderWizardMixin from '@/mixins/OrderWizardMixin';

export default {
    components: { TopSummary, CartSummary },

    mixins: [OrderWizardMixin, OrderMixin],

    data() {
        return {
            reservationId: null,
            isLoading: false,
            isView: true
        }
    },

    computed: {
        ...mapGetters({
            user: 'auth/user',
            reservation: 'orders/single'
        }),
    },

    created() {
        this.reservationId = this.$route.params.id
        this.getReservation(this.reservationId)
    },

    beforeDestroy() {
        this.clearReservation()
    },

    methods: {
        ...mapActions({
            getReservation: 'orders/get',
            updateOrder: 'orders/update',
            clearReservation: 'orders/resetSingle',
        }),
        saveOrder() {
            let payload = Object.assign({}, this.flatOrder);
            payload.reservation = false
            this.isLoading = true
            this.updateOrder({ id: this.reservation.id, payload: payload }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message: 'Ordine aggiornato con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.$router.push('/ordini')
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content
                }
            })
        }
    }
}
</script>
