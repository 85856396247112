<template>
  <div>
    <div class="row">
      <div class="col-12">
        <card>
          DASHBOARD
        </card>
      </div>
    </div>
    <div v-if="dashboardData.length === 0" class="row">
      <div class="col-12">
        <card>
          Attendere il caricamento dei dati...
        </card>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-lg-4" v-if="dashboardData.revenue_per_month && dashboardData.revenue_per_month.length">
        <card :title="'Fatturato per Mese (ultimi 12 mesi)'">
          <el-table :data="dashboardData.revenue_per_month" style="width: 100%">
            <el-table-column prop="month_year" label="Mese">
            </el-table-column>
            <el-table-column prop="total" label="Totale" align="right">
              <template slot-scope="scope">
                <span v-if="scope.row.increment_perc != 'nd'" :class="getIncrementPercClass(parseInt(scope.row.increment_perc))">({{ (scope.row.increment_perc > 0) ? '+' : '' }}{{ scope.row.increment_perc }}%)</span> <span>{{ scope.row.total }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="text-right font-weight-800 mr-3">
            {{ dashboardData.total_revenue_in_year }}
          </div>
        </card>
      </div>

      <div class="col-lg-4" v-if="dashboardData.paid_commissions && dashboardData.paid_commissions.length && $canViewCommissions()">
        <card title="Provvigioni liquidate per Mese">
          <el-table :data="dashboardData.paid_commissions" style="width: 100%">
            <el-table-column prop="month_year" label="Mese">
            </el-table-column>
            <el-table-column prop="total" label="Totale" align="right">
              <template slot-scope="scope">
                <span>{{ scope.row.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="text-right font-weight-800 mr-3">
            {{ dashboardData.tot_paid_commissions.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
          </div>
        </card>
      </div>

      <div class="col-lg-4" v-if="dashboardData.total_commissions && dashboardData.total_commissions.length && $canViewCommissions()">
        <card title="Provvigioni maturate per Mese">
          <el-table :data="dashboardData.total_commissions" style="width: 100%">
            <el-table-column prop="month_year" label="Mese">
            </el-table-column>
            <el-table-column prop="total" label="Totale" align="right">
              <template slot-scope="scope">
                <span>{{ scope.row.total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <div class="text-right font-weight-800 mr-3">
            {{ dashboardData.tot_total_commissions.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' }) }}
          </div>
        </card>
      </div>

      <div class="col-lg-4" v-if="dashboardData.top_products && dashboardData.top_products.length">
        <card :title="'Top 5 Prodotti ' + new Date().getFullYear()">
          <el-table :data="dashboardData.top_products" style="width: 100%">
            <el-table-column prop="products" label="Prodotto" :min-width="60">
              <template slot-scope="scope">
                <span>{{ scope.row.product.description }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orders" label="Totale" align="right" :min-width="40">
            </el-table-column>
          </el-table>
          <a href="/vendite-per-prodotto" style="float: right;">Mostra tutti</a>
        </card>
      </div>

      <div class="col-lg-4" v-if="dashboardData.top_customers && dashboardData.top_customers.length">
        <card :title="'Top 5 Clienti ' + new Date().getFullYear()">
          <el-table :data="dashboardData.top_customers" style="width: 100%">
            <el-table-column prop="customers" label="Cliente" :min-width="60">
              <template slot-scope="scope">
                <span>{{ scope.row.customer.business_name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="orders" label="Totale" align="right" :min-width="40">
              <template slot-scope="scope">
                <span>{{ Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(
                        parseFloat(scope.row.orders).toFixed(2),
                    ) }}</span>
              </template>
            </el-table-column>
          </el-table>
          <a href="/vendite-per-cliente" style="float: right;">Mostra tutti</a>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { Table, TableColumn } from 'element-ui'

export default {
  data () {
    return {
      showSpinner: true
    }
  },
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn
  },
  created () {
    this.fetchDashboardData()
  },
  computed: {
    ...mapGetters({
      dashboardData: 'dashboard/items'
    })
  },
  methods: {
    ...mapActions({
      fetchDashboardData: 'dashboard/fetch'
    }),
    getIncrementPercClass(value) {
        let cssClass = ''
        if (value < 0) {
            cssClass = 'increment-perc increment-perc-red'
        } else if (value == 0) {
            cssClass = 'increment-perc increment-perc-yellow'
        } else if (value > 0) {
            cssClass = 'increment-perc increment-perc-green'
        }
        return cssClass
    }
  }
}
</script>
<style>
.increment-perc {
    font-size: 11px;
    padding-right: 6px;
    font-weight: bold;
}
.increment-perc-red {
    color: #9f0c0c;
}
.increment-perc-yellow {
    color: #9f870c;
}
.increment-perc-green {
    color: #0c9f13;
}
</style>
