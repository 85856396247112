<template>
    <div class="row">
        <div class="col-12 mt-3">
            <h4>Metodo di Pagamento</h4>
            <div class="row">
                <div v-for="paymentMethod in activePaymentMethods" class="col-md-6 mt-2">
                    <base-radio v-model="selectedRadio" :name="paymentMethod.id" @input="selectPaymentMethod(paymentMethod)">
                        {{ paymentMethod.description }}
                    </base-radio>
                </div>
            </div>
            <div class="row" v-if="(selectedRadio == 2 || selectedRadio == 12 || selectedRadio == 13) && !order.customer.notes">
                <div class="col-md-12 mt-4" style="color: red; font-weight: bold;">
                  Per selezionare il pagamento tramite RI.BA. è necessario inserire il codice IBAN nell'anagrafica del cliente.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        },
        paymentMethods: {
            type: Array,
            default: () => {
                return []
            }
        }
    },

    data() {
        return {
            selectedRadio: null
        }
    },

    watch: {
        order: {
            handler(val) {
                if (val && val.payment_method && val.payment_method.id) {
                    this.selectedRadio = val.payment_method.id
                }
            },
            immediate: true,
            deep: true
        }
    },

    computed: {
        activePaymentMethods() {
            return this.paymentMethods.filter( (pm) => pm.active )
        }
    },

    methods: {
        validate() {
            return this.order.payment_method &&
                ((this.order.payment_method.id != 2 && this.order.payment_method.id != 12 && this.order.payment_method.id != 13) || this.order.customer.notes)
        },
        selectPaymentMethod(paymentMethod) {
            this.$emit('onPaymentMethodSelection', paymentMethod)
        }
    }
}
</script>