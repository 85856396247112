<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header
          title="AGENTI"
          @backClick="$router.back()"
        />

        <list-table
          v-model="query"
          :pagination="pagination"
          :columns="tableColumns"
          :items="agents"
          :search-fields="searchFields"
          :meta="meta"
          :creable="false"
          :deletable="false"
          name-prop="last_name"
          @onEdit="$router.push(`agenti/${$event.id}/modifica`)"
        />
      </card>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data () {
    return {
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: ''
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
      ],
      tableColumns: [
        {
          prop: 'business_name',
          label: 'Nome',
          sortable: true,
          minWidth: 200
        },
        {
          prop: 'agency.description',
          label: 'Agenzia',
          minWidth: 200
        },
        {
          prop: 'email',
          label: 'Email',
          sortable: true,
          minWidth: 250
        },
        {
          prop: 'superagent',
          label: 'Super Agente',
          sortable: true,
          minWidth: 250
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      agents: 'agents/items',
      meta: 'agents/meta'
    })
  },

  watch: {
    query: {
      handler () {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler (val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created () {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'agents') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy () {
    this.clearAgents()
  },

  methods: {
    ...mapActions({
      fetchAgents: 'agents/fetch',
      clearAgents: 'agents/resetItems'
    }),
    handleFetch () {
      this.fetchAgents(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'agents', query: this.query }))
    },
  }
}
</script>
<style></style>
