<template>
    <div className="row">
        <div className="col-12">
            <card>
                <page-header
                    :title="'VENDITE PER CLIENTE ' + new Date().getFullYear()"
                    @backClick="$router.back()"
                />

                <list-table
                    v-model="query"
                    :pagination="pagination"
                    :columns="tableColumns"
                    :items="salesByCustomer"
                    :search-fields="searchFields"
                    :meta="meta"
                    :creable="false"
                    :deletable="false"
                    :editable="false"
                    :showable="false"
                    :hasActions="false"
                    name-prop="last_name"
                />
            </card>
        </div>
        <div v-if="salesByCustomer.length === 0" class="row">
            <div class="col-12">
                <card>
                    Attendere il caricamento dei dati...
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {ListTable} from '@/theme/components/index'
import secureStorage from '@/utils/secureStorage'

export default {
    components: {
        ListTable
    },

    data() {
        return {
            query: {
                rowsPerPage: 25,
                page: 1,
                sort: null,
                filter: {
                    custom_search: ''
                }
            },
            pagination: {
                perPageOptions: [10, 25, 50, 100],
                total: 0
            },
            searchFields: [],
            tableColumns: [
                {
                    prop: 'business_name',
                    label: 'Nome',
                    sortable: true,
                    minWidth: 200
                },
                {
                    prop: 'orders',
                    label: 'Qta',
                    sortable: true
                },
                {
                    prop: 'total',
                    label: 'Totale',
                    sortable: true
                },
            ]
        }
    },

    computed: {
        ...mapGetters({
            salesByCustomer: 'salesByCustomer/items',
            meta: 'salesByCustomer/meta'
        })
    },

    watch: {
        query: {
            handler() {
                this.handleFetch()
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        }
    },

    created() {
        const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
        if (storedQuery && storedQuery.entity === 'salesByCustomer') {
            this.query = storedQuery.query
        } else {
            this.handleFetch()
        }
    },

    beforeDestroy() {
        this.clearSalesByCustomer()
    },

    methods: {
        ...mapActions({
            fetchSalesByCustomer: 'salesByCustomer/fetch',
            clearSalesByCustomer: 'salesByCustomer/resetItems'
        }),
        handleFetch() {
            this.fetchSalesByCustomer(this.query)
            secureStorage.setItem('tableQuery', JSON.stringify({entity: 'salesByCustomer', query: this.query}))
        },
    }
}
</script>
<style></style>
