<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header title="PRENOTAZIONI" :query="query" @backClick="$router.back()" />
        <list-table v-model="query" :pagination="pagination" :columns="computedColumns" :items="customers" :meta="meta"
          :search-fields="searchFields" name-prop="business_name" hide-default-search :deletable="false"
          :editable="false" :creable="!user.superagent && !$isAdmin()"
          new-button-label="NUOVA PRENOTAZIONE"
          @onNew="$router.push('prenotazioni/nuovo')"
          @onShow="$router.push(`prenotazioni/${$event.id}/visualizza`)">>

          <template #customActions="props">
            <el-tooltip content="Scarica Documento" :effect="props.darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button class="edit btn-link" type="warning" size="sm" icon @click.native="downloadPdf(props.row)">
                <i v-if="!isDownloading" class="tim-icons icon-paper" />
                <i v-else class="fas fa-spinner fa-spin" />
              </base-button>
            </el-tooltip>
            <el-tooltip v-if="(!props.row.order_status.is_expired && !user.superagent && !$isAdmin())" content="Modifica" :effect="props.darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button
                  v-if="props.row.order_status.id == 6"
                  class="edit btn-link" type="warning" size="sm" icon
                  @click.native="handleBackToDraft(props.row.id)"
                >
                <i class="tim-icons icon-pencil" />
              </base-button>
              <base-button
                  v-else-if="!props.row.order_status.is_persistent"
                  class="edit btn-link" type="warning" size="sm" icon
                  @click.native="$router.push(`prenotazioni/${props.row.id}/modifica`)">
                <i class="tim-icons icon-pencil" />
              </base-button>
            </el-tooltip>
            <el-tooltip v-if="(!props.row.order_status.is_expired && !props.row.order_status.is_persistent && !user.superagent && !$isAdmin())" content="Elimina" :effect="props.darkMode ? 'light' : 'dark'" :open-delay="300" placement="top">
              <base-button class="remove btn-link" type="danger" size="sm" icon @click.native="handleDelete(props.row.id)">
                <i class="tim-icons icon-simple-remove" />
              </base-button>
            </el-tooltip>
          </template>
        </list-table>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'
import axios from 'axios'
import swal from 'sweetalert2'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data() {
    return {
      isDownloading: false,
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: '',
          reservation: true,
          quotation: false
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
        {
          type: 'input',
          propName: 'custom_search',
          name: 'Nome'
        }
      ],
      tableColumns: [
        {
          prop: 'agent.business_name',
          label: 'Agente',
          admin: true
        },
        {
          prop: 'customer.business_name',
          label: 'Cliente'
        },
        {
          prop: 'order_status.description',
          label: 'Stato'
        },
        {
          prop: 'reservation_date',
          label: 'Data'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      customers: 'orders/items',
      meta: 'orders/meta'
    }),
    computedColumns() {
      if (this.user.admin) {
        return this.tableColumns
      }
      return this.tableColumns.filter(col => !col.admin)
    }
  },

  watch: {
    query: {
      handler() {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler(val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created() {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if(this.$route.query.custom_search){
      this.query.filter.custom_search = this.$route.query.custom_search
    }
    if (storedQuery && storedQuery.entity === 'reservations') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy() {
    this.clearOrders()
  },

  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetch',
      deleteOrder: 'orders/delete',
      clearOrders: 'orders/resetItems',
      changeOrderToDraft: 'orders/changeOrderToDraft'
    }),
    handleFetch() {
      this.fetchOrders(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'reservations', query: this.query }))
    },
    downloadPdf(order) {
      let orderNumber = order.number ? order.number + '/00_' + order.registration_date : 'DRAFT_' + order.id;
      let filename = 'Vite_Ordine_' + orderNumber + '.pdf'
      if (!this.isDownloading) {
        this.isDownloading = true;
        axios({
          url: '/orders/' + order.id + '/download',
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          let link = document.createElement('a')
          link.href = window.URL.createObjectURL(new Blob([response.data]))
          link.setAttribute('download', filename);
          link.click()
          this.isDownloading = false
        });
      }
    },
    handleDelete(id) {
      swal.fire({
        title: 'Sei sicuro?',
        text: `L'eliminazione della prenotazione sarà irreversibile.`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Si, cancella',
        cancelButtonText: 'No, annulla',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.executeDelete(id)
        }
      })
    },
    executeDelete(id) {
      this.deleteOrder(id).then((res) => {
        this.fetchOrders(this.query)
        this.$notify({
          message:
            'Prenotazione eliminata con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
      })
    },
    handleBackToDraft(id) {
      swal.fire({
        title: 'Sei sicuro?',
        text: `Se riporti la prenotazione in bozza, saranno liberate le giacenze di magazzino`,
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-success btn-fill',
          cancelButton: 'btn btn-danger btn-fill'
        },
        confirmButtonText: 'Si, procedi',
        cancelButtonText: 'No, annulla',
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.executeBackToDraft(id)
        }
      })
    },
    executeBackToDraft(id) {
      this.changeOrderToDraft(id).then((res) => {
        this.fetchOrders(this.query)
        this.$notify({
          message:
              'Prenotazione riportata in bozza.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
      })
    }
  }
}
</script>
