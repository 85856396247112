import { mapActions, mapGetters } from "vuex"

export default {
    data() {
        return {
            loadingPackages: false,
            lastPackagingTypeIndex: null,
            packagingTypes: [
                { codes: ['NAKP3'], min:1, max: 3 },
                { codes: ['NAKP6'], min:4, max: 6 },
                { codes: ['NAKP3', 'NAKP6'], min:7, max: 9 },
                { codes: ['NAKP6', 'NAKP6'], min:10, max: 12 },
                { codes: ['MINIP6040'], min:13, max: 36 },
                { codes: ['MINIP6080'], min:37, max: 120 },
                { codes: ['EPAL1'], min:121, max: 450 },
            ]
        }
    },

    watch: {
        orderObj: {
            async handler(val) {
                if(!this.loadingPackages && this.totalQuantity <= 450) {
                    this.loadingPackages = true;
                    if(val.shipping_method && val.shipping_method.needs_packaging) {
                        let packagingTypeIndex = this.packagingTypes.findIndex(pack => pack.min <= this.totalQuantity && pack.max >= this.totalQuantity);
                        if(packagingTypeIndex != this.lastPackagingTypeIndex) {
                            this.lastPackagingTypeIndex = packagingTypeIndex
                            await this.clearCartFromPacks()
                            if(packagingTypeIndex >= 0) {
                                let packaging = this.packagingTypes[packagingTypeIndex];
                                for (const code of packaging.codes) {
                                    let pack = this.packages.find(pack => pack.product_code == code)
                                    let quantity = packaging.codes.filter(c => c == code).length
                                    if(pack) {
                                        await this.setCartItem({ productData: {product: pack, quantity, replacement: false, gift: false, typology: 'Vendita' }})
                                    }
                                }
                            }
                        } 
                    } else {
                        await this.clearCartFromPacks()
                    }
                    this.loadingPackages = false;
                }
            },
            deep: true
        }
    },

    created() {
        this.fetchPackages()
    },

    computed: {
        ...mapGetters({
            packages: 'packages/items'
        }),
        orderObj() {
            return this.value || this.order || this.reservation
        },
    },

    methods: {
        ...mapActions({
            fetchPackages: 'packages/fetch'
        }),
        clearCartFromPacks() {
            this.orderObj.cart.forEach((cartRow, cartIndex) => {
                this.packages.forEach(pack => {
                    if (cartRow.product.product_code && cartRow.product.product_code === pack.product_code) {
                        let item = {...pack, 'index': cartIndex}
                        this.removeCartItem(item)
                    }
                })
            });
            this.lastPackagingTypeIndex = null
            /*console.log('called clearCartFromPacks')
            this.packages.forEach(pack => {
                this.removeCartItem(pack)
            })*/
        }
    }
}
