<template>
  <card v-if="value">
    <ValidationObserver ref="form">
      <form @submit.prevent="submit">
        <div class="row">
          <div class="col-md-6">
            <base-input
              :value="value.first_name"
              type="text"
              label="Nome"
              placeholder="Nome"
              :readonly="true"
            />
          </div>
          <div class="col-md-6">
            <base-input
              :value="value.last_name"
              type="text"
              label="Cognome"
              placeholder="Cognome"
              :readonly="true"
            />
          </div>
          <div class="col-md-6">
            <base-input
              :value="value.business_name" type="text"
              label="Ragione Sociale"
              placeholder="Ragione Sociale"
              :readonly="true"
            />
          </div>
          <div class="col-md-6">
            <base-input
              :value="value.email"
              type="email"
              label="Indirizzo Email"
              placeholder="Indirizzo Email"
              :readonly="true"
            />
          </div>
          <div class="col-md-12 mt-4">
            <base-checkbox
                @input="updateValue('superagent', $event)"
                :checked="value.superagent"
            >
              Super Agente
            </base-checkbox>
          </div>
            <div class="col-md-12 mt-4">
                <base-checkbox
                    @input="updateValue('show_commissions', $event)"
                    :checked="value.show_commissions"
                >
                    Mostra Provvigioni
                </base-checkbox>
            </div>
            <div class="col-md-12 mt-4">
              <base-checkbox
                @input="updateValue('active', $event)"
                :checked="value.active"
              >
                Utente attivo
              </base-checkbox>
            </div>
        </div>

        <div class="row">
          <div class="col-12 mt-5 d-flex justify-content-end">
            <base-button :loading="isLoading" native-type="submit" type="primary" class="btn-fill">
              Salva
            </base-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>

<script>
import { extend } from 'vee-validate'
import { Select, Option } from 'element-ui'
import { BaseSwitch } from 'src/theme/components/index'

import FormUpdaterMixin from 'src/mixins/FormUpdaterMixin.js'

import swal from 'sweetalert2'

export default {
  components: {
    [Option.name]: Option,
    [Select.name]: Select,
    BaseSwitch
  },
  mixins: [
    FormUpdaterMixin
  ],
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: '',
          password: null,
          password_confirmation: null,
          superagent: false,
          active: false
        }
      }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    apiErrors: {
      type: [Object, String],
      default: () => {
        return {}
      }
    }
  },
  computed: {
    isEdit () {
      return this.value.id != null
    }
  },
  methods: {
    submit () {
      this.$refs.form.validate().then((success) => {
        if (success) {
          this.$emit('submit')
        } else {
          this.$notify({
            message: 'Sono presenti errori di validazione',
            timeout: 5000,
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'danger'
          })
        }
      })
    }
  }
}
</script>
