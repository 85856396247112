export default {
    data() {
        return {
            loadingGifts: false
        }
    },

    watch: {
        orderObj: {
            async handler(val) {
                if(!this.loadingGifts) {
                    this.loadingGifts = true;

                    // intanto cancello tutte le bottiglie omaggio che avevo già aggiunto automaticamente
                    await this.clearCartFromAutomaticGifts()

                    // aggiungo 6 bottiglie omaggio ogni 60 bottiglie acquistate, scelte tra quelle meno costose. Occhio al magazzino!
                    if (val.payment_method.id == 6 && this.totalQuantityWithoutGiftsAndReplacements / 60 > 0) {
                        let totalFreeBottles = Math.floor(this.totalQuantityWithoutGiftsAndReplacements / 60) * 6
                        while (totalFreeBottles > 0) {
                            let product = this.getCheaperOrderableProduct()
                            if (product) {
                                let qty_orderable = product.storage.qty_orderable - this.getTotalOrderedQtyForProduct(product.product_code)
                                let quantity = (qty_orderable >= totalFreeBottles) ? totalFreeBottles : qty_orderable
                                totalFreeBottles = totalFreeBottles - quantity
                                await this.setCartItem({ productData: { product: product, quantity, replacement: false, gift: true, typology: 'Omaggio', automatic_gift: true, 'readonly': true} })
                            } else {
                                break
                            }
                        }
                    }
                    if (this.orderObj.id) {
                        await this.$emit('automaticSave')
                    }
                    this.loadingGifts = false;
                }
            },
            deep: true
        }
    },

    computed: {
        orderObj() {
            return this.value || this.order || this.reservation
        },
    },

    methods: {
        clearCartFromAutomaticGifts() {
            // attenzione che il removeCartItem opera sull'index dell'array cart, ma cart viene re-indicizzato ogni volta che cancelli una riga!
            // quindi le cancello con un ciclo for che va a ritroso, partendo dal fondo dell'array
            let order = {...this.orderObj}
            for (let cartIndex = order.cart.length - 1; cartIndex >= 0; cartIndex--) {
                if (order.cart[cartIndex].automatic_gift) {
                    let item = {...order.cart[cartIndex].product, 'index': cartIndex}
                    this.removeCartItem(item)
                }
            }
        },
        getCheaperOrderableProduct() {
            let order = {...this.orderObj}
            let sortedProducts = order.cart
                .filter(p => p.product.price && !p.automatic_gift && (p.product.storage.qty_orderable - this.getTotalOrderedQtyForProduct(p.product.product_code) > 0) )
                .sort((a, b) => a.product.price - b.product.price)

            //returns the first element if not null
            let cheaperCartRow = sortedProducts.find(x=>x!==undefined)

            let cheaperProduct = null
            if (cheaperCartRow) {
                cheaperProduct = cheaperCartRow.product
            }
            return cheaperProduct
        },
        getTotalOrderedQtyForProduct(product_code) {
            let order = {...this.orderObj}
            let totalQty = 0
            order.cart.forEach(row => {
                if (row.product.product_code == product_code) {
                    totalQty += row.quantity
                }
            })
            return totalQty
        }
    }
}