<template>
    <div className="row">
        <div className="col-12">
            <card>
                <page-header
                    :title="'VENDITE PER PRODOTTO ' + new Date().getFullYear()"
                    @backClick="$router.back()"
                />

                <list-table
                    v-model="query"
                    :pagination="pagination"
                    :columns="tableColumns"
                    :items="salesByProduct"
                    :search-fields="searchFields"
                    :meta="meta"
                    :creable="false"
                    :deletable="false"
                    :editable="false"
                    :showable="false"
                    :hasActions="false"
                    name-prop="last_name"
                />
            </card>
        </div>
        <div v-if="salesByProduct.length === 0" class="row">
            <div class="col-12">
                <card>
                    Attendere il caricamento dei dati...
                </card>
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import {ListTable} from '@/theme/components/index'
import secureStorage from '@/utils/secureStorage'

export default {
    components: {
        ListTable
    },

    data() {
        return {
            query: {
                rowsPerPage: 25,
                page: 1,
                sort: null,
                filter: {
                    custom_search: ''
                }
            },
            pagination: {
                perPageOptions: [10, 25, 50, 100],
                total: 0
            },
            searchFields: [],
            tableColumns: [
                {
                    prop: 'product_code',
                    label: 'COD.',
                    sortable: true,
                },
                {
                    prop: 'brand',
                    label: 'AZIENDA',
                    sortable: true
                },
                {
                    prop: 'description',
                    label: 'VINO',
                    sortable: true,
                    minWidth: 200
                },
                {
                    prop: 'orders',
                    label: 'QTA',
                    sortable: true
                },
            ]
        }
    },

    computed: {
        ...mapGetters({
            salesByProduct: 'salesByProduct/items',
            meta: 'salesByProduct/meta'
        })
    },

    watch: {
        query: {
            handler() {
                this.handleFetch()
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        }
    },

    created() {
        const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
        if (storedQuery && storedQuery.entity === 'salesByProduct') {
            this.query = storedQuery.query
        } else {
            this.handleFetch()
        }
    },

    beforeDestroy() {
        this.clearSalesByProduct()
    },

    methods: {
        ...mapActions({
            fetchSalesByProduct: 'salesByProduct/fetch',
            clearSalesByProduct: 'salesByProduct/resetItems'
        }),
        handleFetch() {
            this.fetchSalesByProduct(this.query)
            secureStorage.setItem('tableQuery', JSON.stringify({entity: 'salesByProduct', query: this.query}))
        },
    }
}
</script>
<style></style>
