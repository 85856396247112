<template>
    <div>
        <div v-if="order && order.customer" class="row">
            <div class="col-md-2 mb-2">
                <b>Cliente</b>
            </div>
            <div class="col-md-4 mb-2">
                {{ order.customer.business_name }}
            </div>
            <div v-if="order.agent" class="col-md-2 mb-2">
                <b>Agente</b>
            </div>
            <div v-if="order.agent" class="col-md-4 mb-2">
                {{ order.agent.business_name }}
            </div>
        </div>
        <div v-if="order && order.customer_address" class="row">
            <div v-if="order.customer_address" class="col-md-6 pt-2 mb-2 cart-summary-address">
                <div class="row">
                    <div class="col-md-4">
                        <b>Indirizzo di spedizione</b>
                    </div>
                    <div class="col-md-8">
                        <div>{{ order.customer_address.address }}</div>
                        <div>{{ order.customer_address.city }}</div>
                        <div>{{ order.customer_address.province }}</div>
                        <div>{{ order.customer_address.cap }}</div>
                    </div>
                </div>
            </div>

            <div v-if="order && (order.payment_method || order.shipping_method)" class="col-md-6 pt-2 mb-2 cart-summary-address">
                <div class="row">
                    <div v-if="order.payment_method" class="col-md-4">
                        <div><b>Metodo di pagamento</b></div>
                    </div>
                    <div v-if="order.payment_method" class="col-md-8">
                        <div>{{ order.payment_method.description }}</div>
                    </div>
                
                    <div v-if="order.shipping_method" class="col-md-4">
                        <div><b>Metodo di spedizione</b></div>
                    </div>
                    <div v-if="order.shipping_method" class="col-md-8">
                        <div>{{ order.shipping_method.description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        }
    }
}
</script>