<template>
    <div>
        <div class="row d-flex justify-content-center">
            <div class="col-12">
                <FormWizard 
                    v-model="reservation"
                    :total="total"
                    :total-discount="totalDiscount"
                    :total-discounted="totalDiscounted"
                    :customer-discount-percentage="customerDiscountPercentage"
                    :discount-percentage="discountPercentage"
                    :total-discounted-with-shipping="totalDiscountedWithShipping"
                    :total-discounted-with-shipping-and-iva="totalDiscountedWithShippingAndIva"
                    :total-quantity="totalQuantity"
                    :total-quantity-without-gifts-and-replacements="totalQuantityWithoutGiftsAndReplacements"
                    :total-unassigned-free-bottles="totalUnassignedFreeBottles"
                    :shipping-costs="shippingCosts"
                    :flat-total="flatTotal"
                    :flat-cart="flatCart"
                    :payment-methods="paymentMethods"
                    :shipping-methods="shippingMethods"
                    :electronic-invoice-routings="electronicInvoiceRoutings"
                    :delivery-notes="deliveryNotes"
                    :states="states"
                    :provinces="provinces"
                    :apiErrors="apiErrors"
                    :is-loading="isLoading"
                    @saveAsDraft="saveDraft"
                    @saveDraftAndReopenForEdit="saveDraftAndReopenForEdit"
                    @saveOrder="saveReservation"
                    @cancelOrder="cancelReservation"
                    @clearErrors="apiErrors = {}"
                />
            </div>
        </div>
    </div>
</template>
<script>
import FormWizard from './FormWizard.vue';
import { mapActions } from 'vuex';
import OrderWizardMixin from '@/mixins/OrderWizardMixin';
import OrderMixin from '@/mixins/OrderMixin';

import swal from 'sweetalert2'

export default {
    mixins: [OrderWizardMixin, OrderMixin],

    components: { FormWizard },

    data() {
        return {
            isLoading: false,
            apiErrors: {},
            reservation: {
                customer: null,
                customer_address: null,
                payment_method: null,
                shipping_method: null,
                cart: [],
                notes: null,
                quotation: false,
                reservation: true,
                delivery_notes: null,
                delivery_floor: null,
                appointment_phone_number: null,
                mandatory_delivery_date: null,
                mandatory_delivery_max_date: null,
                additional_costs: null
            }
        }
    },

    methods: {
        ...mapActions({
            createReservation: 'orders/create',
            createDraft: 'orders/createDraft',
            updateValue: 'orders/updateValue',
            clearReservation: 'orders/resetSingle',
        }),
        cancelReservation() {
            swal.fire({
                title: 'Sei sicuro di voler annullare le modifiche?',
                showCancelButton: true,
                customClass: {
                    confirmButton: 'btn btn-success btn-fill',
                    cancelButton: 'btn btn-danger btn-fill'
                },
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                buttonsStyling: false
            }).then((result) => {
                if (result.value) {
                    this.$router.push('/prenotazioni')
                }
            })
        },
        saveDraft() {
            this.isLoading = true
            this.createDraft({ payload: this.flatOrder }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message: 'Prenotazione (bozza) creata con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.$router.push('/prenotazioni')
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                }
            })
        },
        saveDraftAndReopenForEdit() {
            this.isLoading = true
            this.createDraft({ payload: this.flatOrder }).then((res) => {
                this.isLoading = false
                this.$router.push('/prenotazioni/' + res.data.content.id + '/modifica#3')
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                }
            })
        },
        saveReservation() {
            this.isLoading = true
            this.createReservation({ payload: this.flatOrder }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message: 'Prenotazione creata con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.$router.push('/prenotazioni')
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content
                }
            })
        },
    }
};
</script>
