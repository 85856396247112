<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header :title="pageTitle" :loading="isLoading" @backClick="$router.back()" />
      </card>
      <customer-form
        :value="customer"
        :payment-methods="paymentMethods"
        :electronic-invoice-routings="electronicInvoiceRoutings"
        :states="states"
        :provinces="provinces"
        :api-errors="apiErrors"
        :is-loading="isLoading"
        @submit="handleSubmit"
        @delete="handleDelete"
        @input="updateValue($event)"
        @clearErrors="apiErrors = {}"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import CustomerForm from './Form.vue'

export default {
  components: { CustomerForm },
  data () {
    return {
      customerId: null,
      isLoading: false,
      apiErrors: {}
    }
  },

  computed: {
    ...mapGetters({
      customer: 'customers/single',
      paymentMethods: 'paymentMethods/items',
      electronicInvoiceRoutings: 'electronicInvoiceRoutings/items',
      states: 'states/items',
      provinces: 'provinces/items'
    }),
    pageTitle () {
      return this.customer && this.customer.client_code
        ? `MODIFICA CLIENTE - CODICE CLIENTE: ${this.customer.client_code}`
        : 'MODIFICA CLIENTE'
    }
  },

  created () {
    this.customerId = this.$route.params.id
    this.getCustomer(this.customerId)
    this.fetchPaymentMethods()
    this.fetchElectronicInvoiceRoutings()
    this.fetchStates()
    this.fetchProvinces()
  },

  beforeDestroy () {
    this.clearCustomer()
    this.clearPaymentMethods()
    this.clearElectronicInvoiceRoutings()
    this.clearStates()
    this.clearProvinces()
  },

  methods: {
    ...mapActions({
      getCustomer: 'customers/get',
      fetchPaymentMethods: 'paymentMethods/fetch',
      fetchElectronicInvoiceRoutings: 'electronicInvoiceRoutings/fetch',
      fetchStates: 'states/fetch',
      fetchProvinces: 'provinces/fetch',
      updateCustomer: 'customers/update',
      deleteCustomer: 'customers/delete',
      clearCustomer: 'customers/resetSingle',
      clearPaymentMethods: 'paymentMethods/resetItems',
      clearElectronicInvoiceRoutings: 'electronicInvoiceRoutings/resetItems',
      clearStates: 'states/resetItems',
      clearProvinces: 'provinces/resetItems',
      updateValue: 'customers/updateValue'
    }),
    handleSubmit () {
      this.isLoading = true
      this.updateCustomer({ id: this.customerId, payload: this.customer, cleanPayload: false }).then((res) => {
        this.$notify({
          message: 'Cliente aggiornato con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.$router.push('/clienti')
      }).catch((err) => {
        this.isLoading = false
        if (err.response.status === 422) {
          this.apiErrors = err.response.data.content.message
        }
      })
    },
    handleDelete () {
      this.isLoading = true
      this.deleteCustomer(this.customerId).then((res) => {
        this.isLoading = false
        this.$notify({
          message: 'Cliente eliminato con successo.',
          timeout: 5000,
          icon: '',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          type: 'success'
        })
        this.$router.push('/clienti')
      })
    }
  }
}
</script>
<style>

</style>
