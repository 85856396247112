<template>
    <div v-if="value">
        <ValidationObserver ref="form">
            <form @submit.prevent="submit">
                <div class="row">
                    <div class="col-12">
                        <card>
                            <div class="row">
                                <div class="col-12">
                                    <div clas="row">
                                        <div class="col-12 d-flex justify-content-between mb-4">
                                            <label>
                                                Indirizzo di Spedizione
                                            </label>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-6">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="descrizione" rules="required|max:40" mode="eager">
                                                <base-input label="Descrizione" placeholder="Descrizione" :error="errors[0]" required
                                                    :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]" :value="value.description"
                                                    @input="updateValue(`description`, $event)" />
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="indirizzo" rules="required|min:2" mode="eager">
                                                <base-input required type="text" label="Indirizzo" placeholder="Indirizzo" :error="errors[0]"
                                                    :class="[{ 'has-success': (passed), 'has-danger': (failed) }]" :value="value.address"
                                                    @input="updateValue(`address`, $event)" />
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="città" rules="required|min:2" mode="eager">
                                                <base-input required type="text" label="Città" placeholder="Città" :error="errors[0]"
                                                    :class="[{ 'has-success': (passed), 'has-danger': (failed) }]" :value="value.city"
                                                    @input="updateValue(`city`, $event)" />
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                          <ValidationProvider v-slot="{ passed, failed, errors }" name="provincia" rules="required" mode="eager">
                                            <base-select :value="value.province_id" size="large" label="Provincia" :items="provinces" autocomplete="code"
                                                item-name="code" placeholder="Provincia" filterable required :error="errors[0]"
                                                 :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                                @input="updateValue(`province_id`, $event)" />
                                          </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                          <ValidationProvider v-slot="{ passed, failed, errors }" name="cap" rules="required|min:2" mode="eager">
                                            <base-input type="text" label="Cap" placeholder="Cap" :value="value.cap" required
                                                :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]" :error="errors[0]"
                                                @input="updateValue(`cap`, $event)" />
                                          </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                          <ValidationProvider v-slot="{ passed, failed, errors }" name="email" rules="required|min:2" mode="eager">
                                                <base-input required type="text" label="Email" placeholder="Email" :value="value.email"
                                                    :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]" required :error="errors[0]"
                                                    @input="updateValue(`email`, $event)" />
                                          </ValidationProvider>
                                        </div>
                                        <div class="col-md-6">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="telefono"
                                                :rules="{ required: (notesData.deliveryMode == 2 || notesData.deliveryMode == 3) }"
                                                mode="eager">
                                                <base-input type="text" label="Telefono" placeholder="Telefono" :value="value.telephone"
                                                    :error="errors[0]"
                                                    :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                                    @input="updateValue(`telephone`, $event)" />
                                            </ValidationProvider>
                                        </div>
<!--                                        <div class="col-md-6">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="cellulare"
                                                :rules="{ required: (notesData.selectedDeliveryTypes.indexOf('Appuntamento') >= 0 && !value.telephone) }"
                                                mode="eager">
                                                <base-input type="text" label="Cellulare" placeholder="Cellulare" :value="value.cellphone"
                                                    :error="errors[0]"
                                                    :class="[{ 'has-success': (passed) }, { 'has-danger': (failed) }]"
                                                    @input="updateValue(`cellphone`, $event)" />
                                            </ValidationProvider>
                                        </div>-->
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12 mt-4">
                                            <ValidationProvider v-slot="{ passed, failed, errors }" name="Modalità di consegna" rules="required" mode="eager">
                                                <base-select
                                                  required
                                                  :value="notesData.deliveryMode"
                                                  label="Modalità di consegna"
                                                  :items="deliveryModes"
                                                  item-name="name"
                                                  placeholder="Modalità di consegna"
                                                  @input="updateDeliveryMode($event)"
                                                  :class="[{ 'has-success': (passed), 'has-danger': (failed) }]"
                                                  :error="errors[0]"
                                                />
                                            </ValidationProvider>
                                        </div>
                                        <div class="col-md-12 mt-4" v-if="notesData.deliveryMode == 1 || notesData.deliveryMode == 2">
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <label>Orario di consegna</label>
                                                </div>
                                                <div class="col-6 col-md-2">
                                                    <base-select v-model="notesData.from1" size="small" label="dalle" :items="hoursArray" placeholder="dalle" flat />
                                                </div>
                                                <div class="col-6 col-md-2">
                                                    <base-select v-model="notesData.to1" size="small" label="alle" :items="hoursArray" placeholder="alle" flat />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2">
                                                </div>
                                                <div class="col-6 col-md-2">
                                                    <base-select v-model="notesData.from2" size="small" label="dalle" :items="hoursArray" placeholder="dalle" flat />
                                                </div>
                                                <div class="col-6 col-md-2">
                                                    <base-select v-model="notesData.to2" size="small" label="alle" :items="hoursArray" placeholder="alle" flat />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mt-4 mb-3" v-if="notesData.deliveryMode == 1 || notesData.deliveryMode == 2">
                                            <div class="row">
                                                <div class="col-md-2"><label class="mr-3">Giorni di chiusura</label></div>
                                                <div class="col-md-10"><base-checkbox v-for="day in days" v-model="notesData.selectedDays" :value="day" inline>{{ day.code }}</base-checkbox></div>
                                            </div>
                                        </div>
<!--                                        <div class="col-md-12 mt-3">
                                            <div class="row">
                                                <div class="col-md-2"><label>Note di Consegna</label></div>
                                                <div class="col-md-10">
                                                    <div class="row">
                                                        <div v-for="deliveryType in deliveryTypes" class="col-md-12">
                                                            <base-checkbox v-model="notesData.selectedDeliveryTypes" :value="deliveryType">{{ deliveryType }}</base-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </card>
                    </div>
                </div>

                <card>
                    <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                            <base-button :loading="isLoading" native-type="submit" type="primary" class="btn-fill">
                                Salva
                            </base-button>
                        </div>
                    </div>
                </card>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { extend } from 'vee-validate'
import { required, min, max, email } from 'vee-validate/dist/rules'
import swal from 'sweetalert2'

import FormUpdaterMixin from 'src/mixins/FormUpdaterMixin.js'

extend('required', required)
extend('min', min)
extend('max', max)
extend('email', email)

export default {
    mixins: [
        FormUpdaterMixin
    ],
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    customer_id: null,
                    description: null,
                    address: null,
                    city: null,
                    cap: null,
                    province_id: null,
                    email: null,
                    telephone: null,
                    cellphone: null,
                    notes: null,
                    notes_data: null
                }
            }
        },
        provinces: {
            type: Array,
            default: () => {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        apiErrors: {
            type: [Object, String],
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            isLoaded: false,
            days: [
                { code: 'L', value: 'Lunedì' },
                { code: 'Ma', value: 'Martedì' },
                { code: 'Me', value: 'Mercoledì' },
                { code: 'G', value: 'Giovedì' },
                { code: 'V', value: 'Venerdì' }
            ],
            deliveryTypes: [
                /*'Scarico in Cantina',
                'Consegna al piano',
                'Facchinaggio',
                'Centro storico',*/
                'Appuntamento'
            ],
            notesData: {
                from1: null,
                to1: null,
                from2: null,
                to2: null,
                selectedDays: [],
                selectedDeliveryTypes: [],
                deliveryMode: null
            },
            deliveryModes: [
                { id: 1, name: 'orario di consegna + giorni di chiusura'},
                { id: 2, name: 'orario di consegna + giorni di chiusura + preavviso telefonico'},
                { id: 3, name: 'appuntamento'}
            ]
        }
    },
    watch: {
        notesData: {
            handler(val) {
              this.updateValue(`notes_data`, val)
            },
            deep: true
        },
    },
    created() {
        if (this.isEdit) {
            if (this.value.notes_data) {
                this.notesData = JSON.parse(this.value.notes_data)
            } else {
                this.notesData = {
                    from1: null,
                    to1: null,
                    from2: null,
                    to2: null,
                    selectedDays: [],
                    selectedDeliveryTypes: [],
                    deliveryMode: null
                }
            }
        }
    },
    computed: {
        isEdit() {
            return this.value.id != null
        },
        hoursArray() {
            var hrs = [];
            for (var i = 16; i <= 37; i++) {
                var n = i % 2 == 0 ? i / 2 + '.00' : (i + 1) / 2 - 1 + '.30';
                if (n < 10) {
                    n = '0' + n;
                }
                hrs.push(n);
            }
            return hrs;
        }
    },
    methods: {
        submit() {
            this.$refs.form.validate().then((success) => {
                if (success) {
                    this.$emit('submit')
                } else {
                    this.$notify({
                        message: 'Sono presenti errori di validazione',
                        timeout: 5000,
                        horizontalAlign: 'right',
                        verticalAlign: 'top',
                        type: 'danger'
                    })
                }
            })
        },
        updateDeliveryMode(deliveryMode) {
            if (deliveryMode == 3) {
                this.notesData.selectedDays = []
                this.notesData.from1 = null
                this.notesData.from2 = null
                this.notesData.to1 = null
                this.notesData.to2 = null
            }

            this.notesData.deliveryMode = deliveryMode

        }
    }
}
</script>
