<template>
  <div class="row">
    <div class="col-12">
      <card>
        <page-header title="PREVENTIVI" :query="query" @backClick="$router.back()" />
        <list-table v-model="query" :pagination="pagination" :columns="tableColumns" :items="customers" :meta="meta"
          :search-fields="searchFields" name-prop="number" hide-default-search :deletable="false"
          new-button-label="NUOVO PREVENTIVO"
          @onNew="$router.push('ordini/nuovo')" @onShow="true" @onEdit="$router.push(`ordini/${$event.id}/modifica`)">
        </list-table>
      </card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { ListTable } from '@/theme/components/index'

import secureStorage from '@/utils/secureStorage'

export default {
  components: {
    ListTable
  },

  data() {
    return {
      query: {
        rowsPerPage: 10,
        page: 1,
        sort: null,
        filter: {
          custom_search: '',
          quotation: true,
          reservation: false
        }
      },
      pagination: {
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchFields: [
        {
          type: 'input',
          propName: 'number',
          name: 'Nome'
        }
      ],
      tableColumns: [
        {
          prop: 'number',
          label: 'Numero',
          sortable: true,
          minWidth: 200
        },
        {
          prop: 'created_at',
          label: 'Data',
          sortable: true,
          minWidth: 200
        },
        {
          prop: 'customer.business_name',
          label: 'Cliente',
          minWidth: 200
        },
        {
          prop: 'total',
          label: 'Importo',
          minWidth: 200
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      customers: 'orders/items',
      meta: 'orders/meta'
    })
  },

  watch: {
    query: {
      handler() {
        this.handleFetch()
      },
      deep: true
    },
    meta: {
      handler(val) {
        this.pagination.total = val.total
      },
      deep: true
    }
  },

  created() {
    const storedQuery = JSON.parse(secureStorage.getItem('tableQuery'))
    if (storedQuery && storedQuery.entity === 'quotations') {
      this.query = storedQuery.query
    } else {
      this.handleFetch()
    }
  },

  beforeDestroy() {
    this.clearOrders()
  },

  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetch',
      clearOrders: 'orders/resetItems'
    }),
    handleFetch() {
      this.fetchOrders(this.query)
      secureStorage.setItem('tableQuery', JSON.stringify({ entity: 'quotations', query: this.query }))
    }
  }
}
</script>
