<template>
    <div>
        <list-table v-model="query" :pagination="pagination" :columns="tableColumns" :items="customerAddresses" :meta="meta"
            new-button-label="NUOVO INDIRIZZO"
            @onNew="createModal=true" :search-fields="searchFields" name-prop="address" hide-default-search :deletable="false"
            :showable="false" selectable
            @rowSelected="selectCustomerAddress" @onEdit="editCustomerAddress" :selected-row-value="order.customer_address">
        </list-table>

        <modal :show.sync="createModal" @close="closeModal">
            <div class="row">
                <div v-if="createModal" class="col-12">
                    <CustomerAddressForm v-model="customerAddress" :provinces="provinces"
                        :api-errors="apiErrors" :is-loading="isLoading" @submit="handleSubmit" @input="updateValue($event)"
                        @clearErrors="apiErrors = {}" />
                </div>
            </div>
        </modal>

    </div>
</template>

<script>
import { ListTable, Modal } from '@/theme/components/index'
import { mapActions, mapGetters } from 'vuex';
import CustomerAddressForm from '@/pages/Dashboard/CustomerAddresses/Form.vue'

export default {
    components: {
        ListTable,
        Modal,
        CustomerAddressForm
    },

    props: {
        order: {
            type: Object,
            default: () => {
                return {}
            }
        },
        customer: {
            type: Object,
            default: () => {
                return {}
            }
        },
        provinces: {
            type: Array,
            default: () => {
                return []
            }
        }
    },

    data() {
        return {
            createModal: false,
            apiErrors: {},
            isLoading: false,
            customerAddress: {
                customer_id: null,
                description: null,
                address: null,
                city: null,
                cap: null,
                province_id: null,
                email: null,
                telephone: null,
                cellphone: null,
                notes: null
            },
            query: {
                rowsPerPage: 10,
                page: 1,
                sort: null,
                filter: {
                    custom_search: '',
                    customer_id: null
                }
            },
            pagination: {
                perPageOptions: [5, 10, 25, 50],
                total: 0
            },
            searchFields: [
                {
                    type: 'input',
                    propName: 'custom_search',
                    name: 'Cerca'
                }
            ],
            tableColumns: [
                {
                    prop: 'address',
                    label: 'Indirizzo',
                    sortable: true,
                    minWidth: 100
                },
                {
                    prop: 'city',
                    label: 'Città',
                    sortable: true,
                },
                {
                    prop: 'province',
                    label: 'Prov.',
                    sortable: true,
                    minWidth: 80,
                },
                {
                    prop: 'cap',
                    label: 'CAP',
                    sortable: true,
                    minWidth: 100
                },
                {
                    prop: 'email',
                    label: 'E-mail',
                    sortable: true,
                    minWidth: 100
                },
            ]
        }
    },

    watch: {
        query: {
            handler() {
                this.fetchCustomerAddresses(this.query)
            },
            deep: true
        },
        meta: {
            handler(val) {
                this.pagination.total = val.total
            },
            deep: true
        },
        customer: {
            handler(val) {
                if(val && val.id) {
                    this.customerAddress.customer_id = val.id
                    this.query.filter.customer_id = val.id
                }
            },
            immediate: true
        }
    },

    beforeDestroy() {
        this.clearCustomerAddresses()
    },

    computed: {
        ...mapGetters({
            customerAddresses: 'customerAddresses/items',
            meta: 'customerAddresses/meta'
        })
    },
    methods: {
        ...mapActions({
            fetchCustomerAddresses: 'customerAddresses/fetch',
            createCustomerAddress: 'customerAddresses/create',
            updateCustomerAddress: 'customerAddresses/update',
            clearCustomerAddresses: 'customerAddresses/resetItems',
            updateValue: 'customerAddresses/updateValue'
        }),
        closeModal() {
            this.createModal = false
            this.customerAddress = {
                customer_id: null,
                description: null,
                address: null,
                city: null,
                cap: null,
                province_id: null,
                email: null,
                telephone: null,
                cellphone: null,
                notes: null
            }
        },
        validate() {
            return this.order.customer_address;
        },
        selectCustomerAddress(customerAddress) {
            this.$emit('onCustomerAddressSelection', customerAddress)
        },
        resetCustomerAddressForm() {
            this.customerAddress = {
                customer_id: this.order.customer.id,
                description: null,
                address: null,
                city: null,
                cap: null,
                province_id: null,
                email: null,
                telephone: null,
                cellphone: null,
                notes: null
            }
        },
        editCustomerAddress(customerAddress) {
            this.customerAddress = Object.assign({}, customerAddress);
            this.createModal = true
        },
        handleSubmit() {
            if (this.customerAddress.id) {
                this.handleUpdate()
            } else {
                this.handleCreate()
            }
        },
        handleCreate() {
            this.isLoading = true
            this.createCustomerAddress({ payload: this.customerAddress }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message:
                        'Indirizzo cliente creato con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.fetchCustomerAddresses(this.query)
                this.createModal = false
                this.resetCustomerAddressForm()
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                };
            })
        },
        handleUpdate() {
            this.isLoading = true
            this.updateCustomerAddress({ id: this.customerAddress.id, payload: this.customerAddress }).then((res) => {
                this.isLoading = false
                this.$notify({
                    message:
                        'Indirizzo cliente aggiornato con successo.',
                    timeout: 5000,
                    icon: '',
                    horizontalAlign: 'right',
                    verticalAlign: 'top',
                    type: 'success'
                })
                this.fetchCustomerAddresses(this.query).then(r => {
                    if (this.order.customer_address) {
                        let refreshedCustomerAddress = this.customerAddresses.find(c => c.id === this.order.customer_address.id);
                        this.selectCustomerAddress(refreshedCustomerAddress)
                    }
                })
                this.createModal = false
                this.resetCustomerAddressForm()
            }).catch((err) => {
                this.isLoading = false
                if (err.response.status === 422) {
                    this.apiErrors = err.response.data.content.message
                };
            })
        }
    }
}
</script>