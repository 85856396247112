import { mapActions, mapGetters } from 'vuex'

export default {
    created() {
        this.fetchPaymentMethods()
        this.fetchShippingMethods()
        this.fetchElectronicInvoiceRoutings()
        this.fetchStates()
        this.fetchProvinces()
        this.fetchDeliveryNotes()
    },

    beforeDestroy() {
        this.clearPaymentMethods()
        this.clearShippingMethods()
        this.clearElectronicInvoiceRoutings()
        this.clearStates()
        this.clearProvinces()
        this.clearDeliveryNotes()
    },

    computed: {
        ...mapGetters({
            paymentMethods: 'paymentMethods/items',
            shippingMethods: 'shippingMethods/items',
            electronicInvoiceRoutings: 'electronicInvoiceRoutings/items',
            states: 'states/items',
            provinces: 'provinces/items',
            deliveryNotes: 'deliveryNotes/items'
        })
    },

    methods: {
        ...mapActions({
            fetchPaymentMethods: 'paymentMethods/fetch',
            fetchShippingMethods: 'shippingMethods/fetch',
            fetchElectronicInvoiceRoutings: 'electronicInvoiceRoutings/fetch',
            fetchStates: 'states/fetch',
            fetchProvinces: 'provinces/fetch',
            fetchDeliveryNotes: 'deliveryNotes/fetch',
            clearPaymentMethods: 'paymentMethods/resetItems',
            clearShippingMethods: 'shippingMethods/resetItems',
            clearElectronicInvoiceRoutings: 'electronicInvoiceRoutings/resetItems',
            clearStates: 'states/resetItems',
            clearProvinces: 'provinces/resetItems',
            clearDeliveryNotes: 'deliveryNotes/resetItems'
        })
    }
}